<template>
  <div class="downloads-wrapper">
    <div class="downloads-banner">
      <div class="downloads-banner-main">
        <div>
          <img src="@/assets/image/resource/phone.png" />
        </div>
        <div>
          <div>{{ $t('dowanload.h1') }}</div>
          <div>{{ $t('dowanload.h2') }}</div>
          <div>{{ $t('dowanload.des') }}</div>
          <div class="downloads-code">
            <div class="downloads-code-left">
              <div>
                <img :src="downloadImg[downloadactive]" />
              </div>
              <div>{{ $t('dowanload')[downloadactive] }}</div>
            </div>
            <div class="downloads-code-ght">
              <div @click="downloadactive = 'iphone'">{{ $t('dowanload.iphonetext') }}</div>
              <div @click="downloadactive = 'android'">{{ $t('dowanload.androidtext') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="downloads-main">
      <div class="downloads-main-content">
        <div class="title">{{ $t('relatedDownloads') }}</div>
        <div class="list">
          <div
            v-for="(item, index) in downloadList"
            :key="index"
            @click="download(item)"
          >
            <div class="icon">
              <img :src="item.icon" />
            </div>
            <div class="text">
              {{ $t(item.prop) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { configDownload } from '@/utils'
export default {
  name: 'Downloads',
  metaInfo() {
    return {
      title: this.$t('downloads')
    }
  },
  data() {
    return {
      downloadactive: 'iphone',
      downloadImg: {
        iphone: require('@/assets/image/resource/dwonlaod-iphone-min.png'),
        android: require('@/assets/image/resource/dwonlaod-anroid-min.png')
      },
      downloadList: configDownload.list
    }
  },
  methods: {
    download(item) {
      if (item.link) window.open(item.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.downloads-wrapper {
  .downloads-banner {
    width: 100%;
    height: 1314px;
    background: url('~@/assets/image/resource/download.jpg') no-repeat center center;
    display: flex;
    justify-content: center;
    align-items: center;
    .downloads-banner-main {
      width: 100%;
      max-width: 1200px;
      display: flex;
      height: 777px;
      justify-content: space-between;
      &>div:nth-child(1) {
        width: 52%;
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
      }
      &>div:nth-child(2) {
        width: 42%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        &>div:nth-child(1),&>div:nth-child(2) {
          color: rgba(51, 51, 51, 1);
          font-size: 52px;
          line-height: 73px;
          margin: 6px 0;
          font-weight: bold;
        }
        &>div:nth-child(3) {
          font-size: 22px;
          color: rgba(51, 51, 51, 1);
          line-height: 40px;
          width: 334px;
          margin: 20px 0;          
        }
        .downloads-code {
          display: flex;
          height: 270px;
          align-items: center;
          margin-top: 144px;
          .downloads-code-left {
            width: 43%;
            height: 261px;
            background: rgba(255, 255, 255, 1);
            padding: 20px;
            border-radius: 8px;
            flex-shrink: 0;
            img {
              width: 100%;
            }
            &>div:nth-child(2) {
              width: 100%;
              text-align: center;
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              line-height: 25px;
              margin-top: 16px;
            }
          }
          .downloads-code-ght {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            width: 51%;
            &>div {
              width: 100%;
              height: 110px;
              border-radius: 4px;
              background: rgba(51, 51, 48, 1);
              color: #fff;
              font-size: 20px;
              display: flex;
              justify-content: center;
              align-self: center;
              margin-bottom: 40px;
              line-height: 110px;
              cursor: pointer;
              &:nth-child(2) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .title {
    width: 100%;
    line-height: 54px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
    font-size: 42px;
    padding: 80px 0;
    font-weight: bold;
  }
  .downloads-main {
    width: 100%;
    background: rgba(245, 246, 247, 1);
    padding-bottom: 87px;
    .downloads-main-content {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        &>div {
          width: 31.66%;
          height: 320px;
          cursor: pointer;
          background: #fff;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 10px;
          margin-bottom: 30px;
          &>.text {
            color: rgba(0, 0, 0, 1);
            font-size: 32px;
            line-height: 45px;
            width: 80%;
            overflow: hidden;
            height: 45px;
            margin-top: 38px;
            text-align: center;
          }
          // &:hover {
          //   box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          //   transition: all 0.3s;
          // }
        }
      }
    }
  }
}
</style>